body {
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  font-size: .8em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.full {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.w99 {
  min-width: 99%;
  max-width: 99%;
  width: 99%;
}

.TabPanel {
  padding: 0;
  margin: 0;
  min-width: 80%;
  max-width: 100%;
  width: 100%;
}

.Tab {
  font-size: 0.8em;
}

.PartsLogo {
  max-width: 256px;
  max-height: 64px;
}

#root {
  height: 100vh;
  width: 100vw;
}

#container {
  position: relative;
  background-color: lightpink;
  border: solid red 1px;
}

#draghandle {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

.MuiInputBase-root {
  max-height: 42px;
}